<template>
  <b-card
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>Estatísticas</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0">
        Atualizado
      </b-card-text>
    </b-card-header>
    <b-overlay
      :show="showLoading"
      variant="transparent"
      opacity="1"
      blur="2px"
      rounded="sm"
    >
      <b-card-body class="statistics-body">
        <b-row>
          <!-- CREDORES -->
          <b-col
            xl="4"
            sm="6"
            class="mb-2 mb-xl-0"
          >
            <b-media no-body>
              <b-media-aside
                class="mr-2"
              >
                <b-avatar
                  size="48"
                  variant="light-primary"
                >
                  <feather-icon
                    size="24"
                    icon="BriefcaseIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0" v-if="statsData">
                  {{ statsData.count_credores }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Credores
                </b-card-text>
                <b-button
                  class="mt-2"
                  size="sm"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="relief-primary"
                  :to="{ name: 'credor-list'}"
                >
                  Visualizar Credores
                </b-button>
              </b-media-body>
            </b-media>
          </b-col>
          <!-- DEVEDORES -->
          <b-col
            xl="4"
            sm="6"
            class="mb-2 mb-xl-0"
          >
            <b-media no-body>
              <b-media-aside
                class="mr-2"
              >
                <b-avatar
                  size="48"
                  variant="light-info"
                >
                  <feather-icon
                    size="24"
                    icon="UserIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0" v-if="statsData">
                  {{ statsData.count_devedores }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Devedores
                </b-card-text>
                <b-button
                  class="mt-2"
                  size="sm"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="relief-info"
                  :to="{ name: 'cliente-list'}"
                >
                  Visualizar Devedores
                </b-button>
              </b-media-body>
            </b-media>
          </b-col>
          <!-- TITULOS -->
          <b-col
            xl="4"
            sm="6"
            class="mb-2 mb-xl-0"
          >
            <b-media no-body>
              <b-media-aside
                class="mr-2"
              >
                <b-avatar
                  size="48"
                  variant="light-danger"
                >
                  <feather-icon
                    size="24"
                    icon="DollarSignIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
               <h4 class="font-weight-bolder mb-0" v-if="statsData">
                  {{ statsData.count_titulos }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Títulos
                </b-card-text>
                <b-button
                  class="mt-2"
                  size="sm"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="relief-danger"
                  :to="{ name: 'titulo-list'}"
                >
                  Visualizar Títulos
                </b-button>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BButton,BOverlay
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BButton,
    BOverlay
  },
  directives: {
    Ripple,
  },
  setup() {

    const statsData = ref(null)
    const showLoading = ref(true)

    axios.get('dashboard/estatisticas')
      .then(response => { statsData.value = response.data; showLoading.value = false })
      .catch(error => console.log(error))
    
    return {
      statsData,
      showLoading
    }
  },
}
</script>
