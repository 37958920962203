<template>
  <b-card no-body border-variant="secondary" bg-variant="transparent" class="shadow-none">
    <b-card-header>
      <b-card-title>Dívidas</b-card-title>
    </b-card-header>

    <b-overlay
      :show="showLoading"
      variant="transparent"
      opacity="1"
      blur="2px"
      rounded="sm"
    >
      <b-card-body v-if="dividasData">
        <b-row>
          <b-col xl="6" sm="12">
            <b-row>
              <b-col>
                <statistic-card-horizontal
                  icon="BarChartIcon"
                  :statistic="dividasData.count_total_ativo"
                  statistic-title="Quantidade ativo"
                  color="success"
                />
              </b-col>
              <b-col>
                <statistic-card-horizontal
                  icon="DollarSignIcon"
                  :statistic="'R$ '+dividasData.valor_total_ativo"
                  statistic-title="Valor total ativo"
                  color="success"
                />
              </b-col>             
            </b-row>
            <b-row>
              <b-col>
                <statistic-card-horizontal
                  icon="BarChartIcon"
                  :statistic="dividasData.count_total_prescrito"
                  statistic-title="Quantidade prescrito"
                />
              </b-col>
              <b-col>
                <statistic-card-horizontal
                  icon="DollarSignIcon"
                  :statistic="'R$ '+dividasData.valor_total_prescrito"
                  statistic-title="Valor total prescrito"
                />
              </b-col>              
            </b-row>
          </b-col>
          
          <b-col xl="2" md="4" sm="6">
            <statistic-card-vertical
              icon="CalendarIcon"
              :statistic="dividasData.condition_1"
              statistic-title="Vencidos < 90 dias"
              color="info"
            />
          </b-col>
          <b-col xl="2" md="4" sm="6">
            <statistic-card-vertical
              color="warning"
              icon="CalendarIcon"
              :statistic="dividasData.condition_2"
              statistic-title="Vencidos > 90 dias"
            />
          </b-col>
          <b-col xl="2" md="4" sm="6">
            <statistic-card-vertical
              color="danger"
              icon="CalendarIcon"
              :statistic="dividasData.condition_3"
              statistic-title="Vencidos > 180 dias"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody, BOverlay
} from 'bootstrap-vue'

import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import axios from '@axios'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BOverlay,
    StatisticCardHorizontal,
    StatisticCardVertical
  },
  setup() {

    const dividasData = ref(null)
    const showLoading = ref(true)

    axios.get('dashboard/dividas')
      .then(response => { dividasData.value = response.data; showLoading.value = false })
      .catch(error => console.log(error))
    
    return {
      dividasData,
      showLoading
    }
  },
}
</script>
