<template>
  <b-card no-body border-variant="secondary" bg-variant="transparent" class="shadow-none">
    <b-card-header>
      <b-card-title>Cobranças</b-card-title>
    </b-card-header>

    <b-overlay
      :show="showLoading"
      variant="transparent"
      opacity="1"
      blur="2px"
      rounded="sm"
    >
      <b-card-body v-if="cobrancasData">
        <b-row>
          <!-- Resumo mensal -->
          <b-col xl="5" md="8" sm="6">
            <b-card
              class="card-app-design"
            >
              <b-badge variant="light-primary">
                MÊS ATUAL
              </b-badge>
              <b-card-title class="mt-1 mb-75">
                Resumo mensal de cobranças
              </b-card-title>
              <b-card-text class="font-small-2 mb-2">
                Verifique aqui como foi o andamento de suas ações, acompanhando os números de perto.
              </b-card-text>       
              
              <div class="design-planning-wrapper mt-2 ">
                <div
                  class="design-planning"
                >
                  <b-badge
                    variant="primary"
                    class="mr-1"
                  >
                    COBRANÇAS REALIZADAS
                  </b-badge>
                  <h4 class="mt-1 mb-0">
                    {{ cobrancasData.cobrancas_realizadas }}
                  </h4>
                </div>

                <div
                  class="design-planning"
                >
                  <b-badge
                    variant="success"
                    class="mr-1"
                  >
                    COM SUCESSO
                  </b-badge>
                  <h4 class="mt-1 mb-0">
                    {{ cobrancasData.cobrancas_sucesso }}
                  </h4>
                </div>

                <div
                  class="design-planning"
                >
                  <b-badge
                    variant="danger"
                    class="mr-1"
                  >
                    COM FALHA
                  </b-badge>
                  <h4 class="mt-1 mb-0">
                    {{ cobrancasData.cobrancas_falha }}
                  </h4>
                </div>

              </div>          
              <hr>
              <!-- button -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                block
                :to="{ name: 'cobranca-list'}"
              >
                Ir para tela de cobranças
              </b-button>
            </b-card>
          </b-col>

          <!-- Resumo envios  -->
          <b-col xl="3" md="4" sm="6">
            <statistic-card-vertical
              color="info"
              icon="ActivityIcon"
              :statistic="cobrancasData.titulos_pagos"
              statistic-title="Dívidas pagas"
            />
            <statistic-card-vertical
              color="success"
              icon="DollarSignIcon"
              :statistic="'R$ '+cobrancasData.valor_recuperado"
              statistic-title="Valor recuperado"
            />
          </b-col>

          <b-col lg="4" md="12" sm="6">
            <statistic-card-horizontal
              icon="MailIcon"
              :statistic="cobrancasData.count_email"
              statistic-title="Email"
            />
            <statistic-card-horizontal
              icon="MessageCircleIcon"
              :statistic="cobrancasData.count_sms"
              statistic-title="SMS"
            />
            <statistic-card-horizontal
              icon="SendIcon"
             :statistic="cobrancasData.count_whats"
              statistic-title="WhatsApp"
            />
          </b-col>
        
        </b-row>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BCard, BCardHeader,BCardText, BCardTitle, BCardBody, BBadge, BButton, BOverlay
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import axios from '@axios'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardText,
    StatisticCardHorizontal,
    StatisticCardVertical,    
    BBadge,
    BButton,
    BOverlay
  },
  directives: {
    Ripple,
  },
  setup() {

    const cobrancasData = ref(null)
    const showLoading = ref(true)

    axios.get('dashboard/cobrancas')
      .then(response => { cobrancasData.value = response.data; showLoading.value = false })
      .catch(error => console.log(error))
    
    return {
      cobrancasData,
      showLoading
    }
  },
}
</script>
