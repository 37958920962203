<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col
        xl="4"
        md="6"
      >
        <card-welcome />
      </b-col>
      <b-col
        xl="8"
        md="6"
      >
        <CardStatsCount />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="12">
        <CardDividas />
      </b-col>
      <!--/ Revenue Report Card -->
    </b-row>

    <b-row class="match-height">
      <b-col lg="12">
        <CardCobrancas />
      </b-col>
      <!--/ Revenue Report Card -->
    </b-row>

   
  </section>
</template>

<script>

import {
  BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody
} from 'bootstrap-vue'

import CardWelcome from './CardWelcome.vue'
import CardStatsCount from './CardStatsCount.vue'
import CardDividas from './CardDividas.vue'
import CardCobrancas from './CardCobrancas.vue'

export default {
  components: {
    BRow,
    BCol,
    CardWelcome,
    CardStatsCount,
    CardDividas,
    CardCobrancas

  },
}
</script>

<style>

</style>
